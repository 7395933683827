<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model.number="$v.form.total.$model"
            :error-messages="getErrors('form.total')"
            label="Количество кодов" 
            name="total" 
            type="number" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-divider class="my-4" />

        <v-row justify="start" align="center">
            <v-col class="col-auto">
                <v-btn 
                    v-if="!waiting"
                    dark 
                    outlined 
                    :color="$const.color.primary" 
                    small
                    @click.prevent.stop="back"
                >
                    <v-icon left dark>
                        mdi-arrow-left
                    </v-icon>
                    Назад к списку
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col class="col-auto">
                <v-btn 
                    dark 
                    :color="$const.color.primary"
                    small
                    :loading="waiting" 
                    @click.prevent.stop="generate"
                >
                    Сгенерировать
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ShortCodeGenerationForm',
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            storeModule: 'short_code',
            form: {
                total: 1
            },
            waiting: false
        };
    },
    validations() {
        return {
            form: {
                total: {
                    required,
                    pattern: function(value) {
                        return value >= 1;
                    }
                }
            }
        }
    },
    methods: {
        async generate() {
            this.waiting = true;
            this.serverErrors = null;

            if (this.validate()) {
                const { success, error, data } = await this.$store.dispatch(`short_code/create`, {
                    ...this.form
                });

                if(success)
                {
                    this.$emit('success', data);
                    this.back();
                    return ;
                }
                else
                    this.serverErrors = error;
            }

            this.waiting = false;
            return;
        },
    }
}
</script>
<style scoped lang="scss">

</style>